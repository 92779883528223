export const getPlanDetails = (useLegacyFreePlan) => {
  return [
    {
      planName: useLegacyFreePlan ? 'Free' : 'Freetool',
      displayName: 'Free',
      price: '0',
      features:
        {
          Users: [false, useLegacyFreePlan ? 'Unlimited' : 'Up to 5'],
          'Web apps': [false, 'Unlimited', 'Up to 5', true, false, false],
          'Mobile apps': [false, 'Unlimited', true, false, false],
          Workflows: [false, 'Unlimited', '500/month', 'Unlimited', 'Unlimited', true, true, true, true, true],
          Database: [false, true, true, false],
          Resources: [false, true, true, false],
          'Security & Support': [false, true, false, false, false, false, false, false, false, false, false]
        }
    },
    {
      planName: 'Team',
      displayName: 'Team',
      price: '10',
      annualStandardUserPrice: '10',
      annualEndUserPrice: '5',
      monthlyStandardUserPrice: '12',
      monthlyEndUserPrice: '7',
      features: {
        Users: [false, 'Up to 25'],
        'Web apps': [false, 'Unlimited', 'Up to 5', true, false, false],
        'Mobile apps': [false, 'Unlimited', true, false, false],
        Workflows: [false, 'Unlimited', '5000/month*', 'Unlimited', 'Unlimited', true, true, true, true, true],
        Database: [false, true, true, 'Staging & Production'],
        Resources: [false, true, true, 'Staging & Production'],
        'Security & Support': [false, true, true, false, false, false, false, false, false, false, false]
      }
    },
    {
      planName: 'Pro',
      displayName: 'Business',
      price: '50',
      annualStandardUserPrice: '50',
      annualEndUserPrice: '15',
      monthlyStandardUserPrice: '65',
      monthlyEndUserPrice: '18',
      features: {
        Users: [false, 'Up to 25'],
        'Web apps': [false, 'Unlimited', 'Unlimited', true, true, false],
        'Mobile apps': [false, 'Unlimited', true, true, false],
        Workflows: [false, 'Unlimited', '5000/month*', 'Unlimited', 'Unlimited', true, true, true, true, true],
        Database: [false, true, true, 'Unlimited'],
        Resources: [false, true, true, 'Unlimited'],
        'Security & Support': [false, true, true, true, true, false, false, false, false, false, false]
      },
    },
    {
      planName: 'Enterprise',
      displayName: 'Enterprise',
      price: 'Custom pricing',
      stripe_plan_id: '',
      features: {
        Users: [false, false],
        'Web apps': [false, 'Unlimited', 'Unlimited', true, true, true],
        'Mobile apps': [false, 'Unlimited', true, true, true],
        Workflows: [false, 'Unlimited', 'Custom', 'Unlimited', 'Unlimited', true, true, true, true, true],
        Database: [false, true, true, 'Unlimited'],
        Resources: [false, true, true, 'Unlimited'],
        'Security & Support': [false, true, true, true, true, true, true, true, true, true, true]
      }
    },
  ]
}

export const getPlanFeaturesCategories = () => {
  return ['Users', 'Web apps', 'Mobile apps', 'Workflows', 'Database', 'Resources', 'Security & Support']
}

export const getPlanFeatures = () => {
  return {
    Users: [
      {
        label: 'User limits',
        tooltip: '',
      }
    ],
    'Web apps': [
      {
        label: 'Web apps',
        tooltip: '',
      },
      {
        label: 'Modules',
        tooltip: 'Reuse groups of components and queries between Retool applications.',
      },
      {label: 'Custom React components', tooltip: ''},
      {label: 'App themes', tooltip: 'Create visual consistency across apps with custom visual themes.'},
      {
        label: 'Branding',
        tooltip: 'Add custom branding to your Retool workspace navigation bars for app presentation mode and end users.'
      },
    ],
    'Mobile apps': [
      {label: 'Mobile apps', tooltip: 'Build native mobile apps and deploy on iOS, Android, or as a PWA.'},
      {label: 'App themes', tooltip: 'Create visual consistency across apps with custom visual themes.'},
      {label: 'Offline mode', tooltip: `Read and write data, even when you're not connected to the internet.`},
      {label: 'White-label apps', tooltip: 'Launch your own branded app to iOS and Android app stores'}
    ],
    Workflows: [
      {label: 'Workflows', tooltip: 'A workflow executes a series of queries based on a specified trigger.'},
      {
        label: 'Workflows runs',
        tooltip: 'A billed run is counted every time a workflow successfully executes when triggered.'
      },
      {
        label: 'Workflows steps',
        tooltip: 'Each block on the canvas that transforms, reads from or writes to your data represents a step in your workflow.'
      },
      {
        label: 'Data throughput',
        tooltip: 'The amount of data passed through your workflow, including input and output.'
      },
      {label: 'Scheduled jobs', tooltip: 'Run your workflow on a set schedule.'},
      {label: 'Webhooks', tooltip: 'Trigger your workflow with a webhook.'},
      {
        label: 'GUI blocks',
        tooltip: 'Format and manipulate data quickly with pre-built blocks like Loop, Filter, and Branch. Customize with code.'
      },
      {label: 'Functions', tooltip: 'Reusable queries with optional parameters that can be called from code.'},
      {label: 'Error handling', tooltip: 'Trigger custom error messages if a workflow fails.'}
    ],
    Database: [
      {label: 'Database editor UI', tooltip: 'Create schema and manage data faster with Retool Database.'},
      {
        label: 'Bring your own PostgreSQL database',
        tooltip: 'Connect Retool Database to your v14 PostgresSQL database.'
      },
      {
        label: 'Environments & schema migrations',
        tooltip: 'Get isolated tables per each environment in your Retool instance. Migrate schema changes between environments.'
      },
    ],
    Resources: [
      {
        label: 'Connect to all databases and APIs',
        tooltip: '',
      },
      {
        label: 'Query Library',
        tooltip: 'Our IDE to build, share, and reuse queries for any database or API.'
      },
      {
        label: 'Staging & production resources',
        tooltip:
          'Test your app against a staging resource (database or API) and seamlessly switch it to production resources when in user mode.',
      },
    ],
    'Security & Support': [
      {
        label: 'Self-hosted deployment',
        tooltip: 'Deploy Retool in your own infrastructure, via Docker, in 15 minutes.',
      },
      {
        label: 'Version release & history',
        tooltip: 'Create and manage releases for your app, and revert to any previous state in its history.',
      },
      {
        label: 'Granular access controls',
        tooltip: 'Custom permission groups for granular control over app access',
      },
      {
        label: 'Audit logs',
        tooltip: 'Track every query run against your databases and APIs, as well as user actions taken in Retool.',
      },
      {
        label: 'Usage analytics',
        tooltip: 'View app usage across all self-hosted deployments in one place.',
      },
      {
        label: 'Source control',
        tooltip: 'Branch-based editing workflows in Retool, compatible with Git',
      },
      {
        label: 'Custom SSO and IdP integration',
        tooltip: 'Integrate with Okta, Active Directory, or any other SAML / OpenID SSO provider. Map roles and groups from your IdP to Retool.',
      },
      {
        label: 'Connect to analytics vendors',
        tooltip: 'Send analytics data to Segment, Google Analytics, etc.',
      },
      {
        label: 'Access to a deployed engineer',
        tooltip: 'A dedicated Deployed Engineer to help ship apps, onboard and train new teams, and implement best practices across your org',
      },
      {
        label: 'Enterprise Support',
        tooltip: 'Enterprise support with a dedicated Customer Success Manager and a shared Slack channel.',
      },
    ]
  }
}
